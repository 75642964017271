<template>
  <div class="confirm-account login-component">
    <div class="image-container"></div>
    <transition appear name="fadeRight" mode="out-in">
      <div class="black-background">
        <div class="container">
          <h2>{{headline1}}</h2>
          <p class="p-unstyled">{{message}}</p>
          <md-button v-if="headline1 !='' && message != ''" :to="{name: 'Login'}" class="md-primary md-raised md-center">
            {{lang.confirmAccount.buttonText}}
          </md-button>
        </div>
      </div>
    </transition>
    <md-progress-bar v-if="loadingStatus" md-mode="query"></md-progress-bar>
  </div>
</template>

<script>
import store from '@/store/index'
import router from '@/router/index'

export default {
  name: 'ConfirmAccount',
  data() {
    return {
      headline1: "",
      message: ""
    }
  },
  created() {
    store.dispatch('confirmAccount', this.$route.query.token)
      .then(result => {
        if (result.data != null) {
          this.headline1 = this.lang.confirmAccount.headline1Success;
          this.message = this.lang.confirmAccount.messageSuccess;
        } else {
          this.headline1 = this.lang.modal.errorHeadline;
          this.message = store.state.spieler.apiReponseErrorMessage;
        }
      })
      .catch(error => {
          this.headline1 = this.lang.modal.errorHeadline;
          this.message = store.state.spieler.apiReponseErrorMessage;
      });
  },
  computed: {
    lang() {
      return store.getters.getLanguage;
    },
    loadingStatus() {
      return store.state.spieler.loadingStatus;
    },
  },
  methods: {
    
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/base';

.image-container {
  background-image: url("/img/beerpong-images/500px/beer-pong-team-mobile-5.jpg");
  background-position: 0% -3%;
}

h2 {
  margin-bottom: 10px;
}

p {
  margin-bottom: 25px;
  text-align: center;
  max-width: 270px;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.md-button {
  &.md-center {
    height: 40px;
    // display: block;
    padding: 0 20px;
    width: auto;
  }
}

@media (min-width: $screen-xs2) {
  .image-container {
    background-image: url("/img/beerpong-images/1200px/beer-pong-team-tablet-5.jpg");
    background-position: 0% -10%;
  }
}


@media (min-width: $screen-sm) {
  .login-component {
    background-image: url("/img/beerpong-images/1200px/beer-pong-team-tablet-2.jpg");
    background-position: center bottom;
  }

  p {
    font-size: 1.8rem;
    margin-bottom: 35px;
    max-width: 270px;
  }
}

@media (min-width: $screen-lg) {
  .login-component {
      background-image: url("/img/beerpong-images/1920px/beer-pong-team-desktop-2.jpg");
      background-position: center bottom;
  }
}

</style>
